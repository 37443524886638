import React from 'react';
import { PropTypes } from 'prop-types';
import { classNames } from 'src/utils/css';
import { Checkmark } from 'src/components/common/Icons';
import { FluidText } from 'src/components/common/FluidText';
import { toXPathData } from 'src/utils/netlify-create/xpath';
import { Markdown } from '../markdown';
import { NumberedBullet } from '../numbered-bullet';
import {
  list_,
  bulletItem_,
  variantSm_,
  variantMdLg_,
  defaultCheckmarkColor_,
} from './list.module.scss';

const SIZES = {
  sm: {
    fluidConfig: {
      all: 'ws-text-md',
    },
    class: variantSm_,
    iconSize: 'sm',
  },
  md: {
    fluidConfig: {
      all: 'ws-text-lg',
    },
    class: variantMdLg_,
    iconSize: 'md',
  },
  lg: {
    fluidConfig: {
      min: 'ws-text-lg',
      max: 'ws-text-xl',
    },
    class: variantMdLg_,
    iconSize: 'md',
  },
};

const ListItemImg = ({ iconColor = defaultCheckmarkColor_, size, ordered, index }) => {
  return (
    <>
      {ordered ? (
        <NumberedBullet textColor={iconColor} value={index + 1} />
      ) : (
        <Checkmark id="ListCheckmarkIcon" size={size} color={iconColor} />
      )}
    </>
  );
};

const ListItem = ({ children, bodySize, iconColor, bgColor, ordered, reset, index }) => (
  <li>
    <FluidText type="div" {...SIZES[bodySize].fluidConfig} className={classNames(bulletItem_)}>
      {!reset && (
        <div className={SIZES[bodySize].class}>
          <ListItemImg
            iconColor={iconColor}
            bgColor={bgColor}
            ordered={ordered}
            index={index}
            size={SIZES[bodySize].iconSize}
          />
        </div>
      )}
      <div {...toXPathData(`List_ListItem[${index}]`)}>{children}</div>
    </FluidText>
  </li>
);

const SemanticWrapper = ({ children, className, ordered, style = {}, reset }) => {
  const wrapperClassName = classNames(className);
  // markdown renderer has some issues with passing className
  const semanticStyle = reset
    ? { listStyleType: ordered ? 'decimal' : 'disc', paddingLeft: 'revert' }
    : { ...{ listStyleType: 'none', paddingLeft: 0 }, ...style };
  return ordered ? (
    <ol className={wrapperClassName} style={semanticStyle}>
      {children}
    </ol>
  ) : (
    <ul className={wrapperClassName} style={semanticStyle}>
      {children}
    </ul>
  );
};

export const List = ({
  className,
  content = [''],
  bodySize = 'md',
  reset = false,
  ordered = false,
  iconColor,
  bgColor,
  textColor,
}) => {
  const listItemProps = { bodySize, reset, iconColor, bgColor };

  const LIST_RENDERER = {
    list: ({ children, ordered: orderedAccordingToReactMarkdown }) => (
      <SemanticWrapper
        reset={reset}
        ordered={orderedAccordingToReactMarkdown}
        style={{ color: textColor }}
      >
        {children}
      </SemanticWrapper>
    ),
    listItem: ({ children, ordered: orderedAccordingToReactMarkdown, index }) => {
      return (
        <ListItem {...listItemProps} ordered={orderedAccordingToReactMarkdown} index={index}>
          {children}
        </ListItem>
      );
    },
  };

  return Array.isArray(content) ? (
    <SemanticWrapper
      className={classNames(list_, className)}
      reset={reset}
      ordered={ordered}
      style={{ color: textColor }}
    >
      {content.map((listItemContent, index) => (
        <ListItem key={listItemContent} {...listItemProps} index={index} ordered={ordered}>
          <Markdown source={listItemContent} renderers={LIST_RENDERER} />
        </ListItem>
      ))}
    </SemanticWrapper>
  ) : (
    <Markdown className={className} source={content} renderers={LIST_RENDERER} />
  );
};

List.propTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  bodySize: PropTypes.oneOf(['sm', 'md', 'lg']),
  reset: PropTypes.bool,
  ordered: PropTypes.bool,
  iconColor: PropTypes.string,
  bgColor: PropTypes.string,
};
