import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import { toObjectId } from '@stackbit/annotations';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { classNames } from 'src/utils/css';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import {
  ComponentContextProvider,
  RefreshCell,
  RefreshGrid,
  useComponentContext,
  GRID_DEFAULT_CONFIG,
} from 'src/components/layout';
import { Background } from 'src/components/common/Background';
import { translations } from './PricingCards.translations';
import {
  CONFIG,
  VARIANTS,
  BenefitCard,
  PdpCard,
  TaxCard,
  HomeCard,
} from './PricingCardsContent.component';
import { cardLink_, active_ } from './PricingCards.module.scss';

const CardContent = ({ variant, ...rest }) => {
  let content;
  switch (variant) {
    case VARIANTS.default:
      content = <BenefitCard variant={variant} {...rest} />;
      break;
    case VARIANTS.pdp:
      content = <PdpCard variant={variant} {...rest} />;
      break;
    case VARIANTS.home:
      content = <HomeCard variant={variant} {...rest} />;
      break;
    case VARIANTS.tax:
      content = <TaxCard variant={variant} {...rest} />;
      break;
    default:
  }
  return content;
};

const PricingCard = ({ tierId, variant, theme, key, entryId, ...rest }) => {
  const cardRef = useRef();
  const { name } = useComponentContext();
  const contentBlockId = camelCase(`${tierId} ${variant === VARIANTS.default ? '' : variant}`);
  const themeId = camelCase(`${contentBlockId} ${theme}`);
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys({
    'en-ca': {
      'pricing-cards::benefit::cta::download::label': 'Download app',
      'pricing-cards::benefit::cta::contact::label': 'Contact our team',
    },
    'fr-ca': {
      'pricing-cards::benefit::cta::download::label': `Télécharger l'application`,
      'pricing-cards::benefit::cta::contact::label': 'Contacter notre équipe',
    },
  });

  const [isActive, setIsActive] = useState(false);
  const handleActive = () => {
    setIsActive(true);
  };
  const handleInactive = () => {
    setIsActive(false);
  };

  return (
    <ComponentContextProvider value={{ name: `${name} ${tierId}` }} key={key}>
      <Background
        as={SmartLink}
        background={CONFIG[themeId].bg}
        ref={cardRef}
        href={CONFIG[themeId].ctaUrl}
        className={classNames(cardLink_, CONFIG[themeId].wrapper, isActive && active_)}
        onMouseEnter={handleActive}
        onMouseLeave={handleInactive}
        onFocus={handleActive}
        onBlur={handleInactive}
        {...entryId ? toObjectId(entryId) : {}}
      >
        <div className={CONFIG.variants[variant]}>
          <CardContent
            isActive={isActive}
            variant={variant}
            theme={theme}
            themeId={themeId}
            contentBlockId={contentBlockId}
            {...rest}
          />
        </div>
      </Background>
    </ComponentContextProvider>
  );
};

const PricingCards = ({ variant = VARIANTS.default, product, theme = 'evo', ...rest }) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  return (
    <RefreshGrid xs={{ columnGap: 0 }} md={{ columnGap: GRID_DEFAULT_CONFIG.gap }} {...rest}>
      {/* eslint-disable react-hooks/rules-of-hooks */}
      {(variant === VARIANTS.default
        ? useTranslation('pricing::benefit-cards')
        : useTranslation(`pricing::pdp-cards::${product}`)
      ).map(tier => (
        <RefreshCell
          key={`pricing-pdp-card-${tier.heading}-${product}`}
          sm={{ width: 8, left: 3 }}
          lg={{ width: 4, left: 'auto' }}
        >
          <PricingCard
            tierId={tier.id}
            theme={theme}
            eyebrow={tier.eyebrow}
            heading={tier.heading}
            subheading={tier.subheading}
            lists={tier.infoLists}
            cta={tier.cta}
            fxRate={tier.fxRate}
            fxRatePost={tier.fxRatePost}
            fxText={tier.fxText}
            variant={variant}
          />
        </RefreshCell>
      ))}
      {/* eslint-enable react-hooks/rules-of-hooks */}
    </RefreshGrid>
  );
};

PricingCards.propTypes = {
  variant: PropTypes.oneOf([VARIANTS.default, VARIANTS.pdp, VARIANTS.home, VARIANTS.tax]),
  theme: PropTypes.oneOf(['evo']),
  product: PropTypes.string,
};

PricingCards.displayName = 'PricingCards';

export { PricingCards, PricingCard };
