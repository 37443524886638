import React from 'react';
import isString from 'lodash/isString';
import { List } from 'src/tapestry/core/list';
import { Markdown } from 'src/tapestry/core/markdown';
import { APP_DOWNLOAD_PATH, CONTACT_FORM_URL, PRICING_PATH } from 'src/utils/urls';
import {
  colorWhite,
  colorText,
  colorSlate90,
  colorSlate95,
  colorLinenBg,
  colorLinenLightBg,
  colorNavyBg,
  colorMidnightBg,
  colorChartreuseBg98,
  colorSkyBg98,
} from 'src/styles/exports.module.scss';
import { useTranslation } from 'src/components/contexts/TranslationContext';
import { classNames } from 'src/utils/css';
import { FluidText } from 'src/components/common/FluidText';
import { Background } from 'src/components/common/Background';
import { ArrowLinkButton } from '../ArrowLinkButton';
import { CTAButton } from '../CTAButton';
import {
  variantDefault_,
  variantPDP_,
  variantHome_,
  variantTax_,
  wrapperDefault_,
  wrapperPDP_,
  wrapperTax_,
  generationPricingBlockBgPdp_,
  pricingBlock_,
  pricingBlockLower_,
  pricingBlockPostText_,
  featureBlockPdpProduct_,
  featureBlockTax_,
  listBlock_,
  listBlockHome_,
  listBlockHeading_,
  ctaButton_,
  mediaContainer_,
  spacing4_,
  eyebrow_,
  mainCopyBlock_,
  heading_,
  headingWrapper_,
  generationEyebrowPDP_,
  premiereEyebrowHome_,
  generationEyebrowHome_,
  fxText_,
  currencyTextAlignMiddle_,
} from './PricingCards.module.scss';

export const VARIANTS = {
  default: 'default',
  pdp: 'pdp',
  home: 'home',
  tax: 'tax',
};

export const CONFIG = {
  variants: {
    default: variantDefault_,
    pdp: variantPDP_,
    home: variantHome_,
    tax: variantTax_,
  },
  coreEvo: {
    wrapper: wrapperDefault_,
    bg: colorWhite,
    heading: colorText,
    pillCurrencyText: colorText,
    pillText: colorText,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorChartreuseBg98,
    pricingBlockBg90: colorSlate95,
    benefitButtonLabel: `pricing-cards::benefit::cta::download::label`,
    ctaUrl: APP_DOWNLOAD_PATH,
  },
  premiumEvo: {
    wrapper: wrapperDefault_,
    bg: colorWhite,
    heading: colorText,
    pillCurrencyText: colorText,
    pillText: colorText,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorSkyBg98,
    pricingBlockBg90: colorLinenBg,
    benefitButtonLabel: `pricing-cards::benefit::cta::contact::label`,
    ctaUrl: CONTACT_FORM_URL,
  },
  generationEvo: {
    wrapper: wrapperDefault_,
    bg: colorWhite,
    heading: colorText,
    pillCurrencyText: colorWhite,
    pillText: colorWhite,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: generationPricingBlockBgPdp_,
    pricingBlockBg90: colorNavyBg,
    benefitButtonLabel: `pricing-cards::benefit::cta::contact::label`,
    ctaUrl: CONTACT_FORM_URL,
  },
  premiumHomeEvo: {
    bg: colorLinenLightBg,
    heading: colorText,
    darkThemeCta: false,
    eyebrow: premiereEyebrowHome_,
    pillCurrencyText: colorText,
    pillText: colorText,
    list: listBlockHome_,
    checkmarkColor: colorText,
    pricingBlockBg: colorSkyBg98,
    pricingBlockBg90: colorSlate90,
    benefitButtonLabel: `pricing-cards::benefit::cta::contact::label`,
    ctaUrl: PRICING_PATH,
  },
  generationHomeEvo: {
    bg: colorMidnightBg,
    heading: colorWhite,
    darkThemeCta: true,
    eyebrow: generationEyebrowHome_,
    list: listBlockHome_,
    checkmarkColor: colorWhite,
    pillCurrencyText: colorWhite,
    pillText: colorWhite,
    pricingBlockBg: generationPricingBlockBgPdp_,
    pricingBlockBg90: colorNavyBg,
    benefitButtonLabel: `pricing-cards::benefit::cta::contact::label`,
    ctaUrl: PRICING_PATH,
  },
  corePdpEvo: {
    wrapper: wrapperPDP_,
    bg: colorSlate95,
    eyebrow: eyebrow_,
    heading: colorText,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorWhite,
    ctaUrl: PRICING_PATH,
  },
  premiumPdpEvo: {
    wrapper: wrapperPDP_,
    bg: colorLinenBg,
    eyebrow: eyebrow_,
    heading: colorText,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorWhite,
    ctaUrl: PRICING_PATH,
  },
  generationPdpEvo: {
    wrapper: wrapperPDP_,
    bg: colorMidnightBg,
    eyebrow: generationEyebrowPDP_,
    heading: colorWhite,
    list: listBlock_,
    checkmarkColor: colorWhite,
    pricingBlockBg: colorWhite,
    ctaUrl: PRICING_PATH,
  },
  basicTaxEvo: {
    wrapper: wrapperTax_,
    bg: colorWhite,
    heading: colorText,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorSkyBg98,
  },
  plusTaxEvo: {
    wrapper: wrapperTax_,
    bg: colorWhite,
    heading: colorText,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorSkyBg98,
  },
  proTaxEvo: {
    wrapper: wrapperTax_,
    bg: colorWhite,
    heading: colorText,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorSkyBg98,
  },
};

const PriceBlock = ({
  className,
  background,
  currency,
  currencyColor,
  currencyPostTextColor,
  text,
}) => {
  const currencyElement = (
    <FluidText type="span" all="ws-display-xs" style={{ color: currencyColor }}>
      {currency}
    </FluidText>
  );
  const netDepositsElement = (
    <span className={pricingBlockPostText_} style={{ color: currencyPostTextColor }}>
      {text}
    </span>
  );
  const completePriceElements = (
    <span className={currencyTextAlignMiddle_}>
      {currencyElement}
      {' '}
      {netDepositsElement}
    </span>
  );

  return (
    <div className={className}>
      <Background className={pricingBlock_} background={background}>
        {completePriceElements}
      </Background>
    </div>
  );
};

const CheckList = ({ lists, themeId }) => {
  return lists?.map(list => (
    <div key={`card-list-${list.heading}`} className={CONFIG[themeId].list}>
      {list.heading && (
        <FluidText className={listBlockHeading_} type="h3" all="ws-text-lg-sans">
          {list.heading}
        </FluidText>
      )}
      <List
        textColor={CONFIG[themeId].checkmarkColor}
        iconColor={CONFIG[themeId].checkmarkColor}
        content={list.items}
      />
    </div>
  ));
};

const Eyebrow = ({ text, themeId }) => (
  <FluidText type="p" all="ws-eyebrow-sm" className={CONFIG[themeId].eyebrow}>
    {text}
  </FluidText>
);

const Heading = ({
  isActive,
  heading,
  headingType,
  subheading,
  themeId,
  arrow,
  headingFontConfig = { all: 'ws-display-md' },
}) => {
  return (
    <div className={mainCopyBlock_}>
      <div className={headingWrapper_}>
        <FluidText
          type={headingType}
          {...headingFontConfig}
          className={heading_}
          style={{ color: CONFIG[themeId].heading }}
        >
          {heading}
        </FluidText>
        {CONFIG[themeId].ctaUrl && arrow && (
          <ArrowLinkButton
            transparentBackground
            isActive={isActive}
            animationDirection="leftToRight"
          />
        )}
      </div>
      {subheading && (
        <FluidText
          type="p"
          all="ws-text-lg"
          style={{ marginTop: spacing4_, color: CONFIG[themeId].heading }}
        >
          {subheading}
        </FluidText>
      )}
    </div>
  );
};

export const PdpCard = ({
  isActive,
  eyebrow,
  heading,
  subheading,
  lists,
  fxRate,
  fxText,
  themeId,
  children,
}) => {
  return (
    <>
      {eyebrow && <Eyebrow text={eyebrow} themeId={themeId} />}
      <Heading
        isActive={isActive}
        heading={heading}
        headingType="h3"
        subheading={subheading}
        themeId={themeId}
        arrow
      />
      <CheckList lists={lists} themeId={themeId} />
      {children}
      <Background
        as="p"
        background={CONFIG[themeId].pricingBlockBg}
        className={featureBlockPdpProduct_}
      >
        <FluidText type="span" all="ws-display-md" className="ws-display-block">
          {fxRate}
        </FluidText>
        <FluidText type="span" all="ws-text-lg" className={classNames(fxText_, 'ws-display-block')}>
          {isString(fxText) ? <Markdown unwrapElements="paragraph" source={fxText} /> : fxText}
        </FluidText>
      </Background>
    </>
  );
};

export const TaxCard = ({
  isActive,
  heading,
  subheading,
  lists,
  fxRate,
  fxRatePost,
  fxText,
  themeId,
}) => {
  return (
    <>
      <Heading
        isActive={isActive}
        heading={heading}
        headingType="h3"
        subheading={subheading}
        themeId={themeId}
        headingFontConfig={{ all: 'ws-display-md-sans' }}
      />
      <CheckList lists={lists} themeId={themeId} />
      <Background className={featureBlockTax_} background={CONFIG[themeId].pricingBlockBg}>
        <div>
          <FluidText type="h4" min="ws-text-2xl-medium" max="ws-text-3xl-medium">
            {fxRate}
            {' '}
            {fxRatePost && (
              <FluidText type="span" all="ws-text-lg">
                {fxRatePost}
              </FluidText>
            )}
          </FluidText>
          <FluidText type="markdown" all="ws-text-lg" className={fxText_}>
            {fxText}
          </FluidText>
        </div>
      </Background>
    </>
  );
};

export const HomeCard = ({ isActive, eyebrow, heading, subheading, lists, themeId, media }) => {
  return (
    <>
      {eyebrow && <Eyebrow text={eyebrow} themeId={themeId} />}
      <Heading
        isActive={isActive}
        heading={heading}
        headingType="h3"
        subheading={subheading}
        themeId={themeId}
        arrow
      />
      <CheckList lists={lists} themeId={themeId} />
      {media && <div className={mediaContainer_}>{media}</div>}
    </>
  );
};

export const BenefitCard = ({
  isActive,
  theme,
  contentBlockId,
  heading,

  subheading,
  lists,
  themeId,
}) => {
  return (
    <>
      <Heading
        isActive={isActive}
        heading={heading}
        headingType="h2"
        subheading={subheading}
        themeId={themeId}
      />
      <PriceBlock
        className={pricingBlockLower_}
        background={CONFIG[themeId].pricingBlockBg90}
        currency={useTranslation(`card::content-block::${contentBlockId}::currency`)}
        currencyColor={CONFIG[themeId].pillCurrencyText}
        currencyPostTextColor={CONFIG[themeId].pillText}
        text={useTranslation('card::content-block::post-text')}
        theme={theme}
      />
      <CheckList lists={lists} themeId={themeId} />
      <div>
        <CTAButton className={ctaButton_} variant="secondary" disabled>
          {useTranslation(CONFIG[themeId].benefitButtonLabel)}
        </CTAButton>
      </div>
    </>
  );
};
